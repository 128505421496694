.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.n-b-off, .n-b-on{
    list-style: none;
    display: flex;
    gap: 2rem;
    white-space: nowrap;
    color:#ffffff ;
    font-size: 1rem;
    font-weight:lighter;
    z-index: 1;
}
.n-b-off>li:hover, .n-b-on>li:hover{
    cursor: pointer;
    color: var(--orange);
}
.logo{
    width: 10rem;
    height: 3rem;
}
.nav-list-icon{
    position: fixed;
    top: 3rem;
    right: 3rem;
    font-size: 3rem;
    cursor: pointer;
    display: none;
    z-index: 1;
}
@media screen and (max-width: 900px) {
    .header{
        width: 100%;
    }
    .n-b-on{
        flex-direction: column;
        background-color: var(--appColor);
        width: 24vw;
        height: 18rem;
        padding: 2rem;
        justify-content: space-between;
        align-items: center;
        font-size: 1.4rem;
        position: fixed;
        top: 6rem;
        right: 3rem;
        animation-name: slideron;
        animation-duration: 1s;
        overflow: hidden;
    }
    .n-b-off{
        display: none;
    }
    @keyframes slideron {
        0%   {height: 0;}
        100% {height: 18rem;}
    } 
    .nav-list-icon{
        display: flex;
    }
}