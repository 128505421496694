h1,h4{
    margin: 0;
    padding:0;
}
.plans-cont{
    position: relative;
}
.plans-head-cont{
    width: 75%;
    display: flex;
    justify-content: space-between;
    margin: auto;
    margin-bottom: 2rem;
}
.plan-cards-cont{
    width: 65%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.plan-one{
width: 25%;
margin-top: 1.5rem;
height: 28rem;
background-color: rgb(101,101,101);
display: flex;
flex-direction: column;
justify-content: space-between;
padding: 1.5rem;
color: #ffffff;
}
.plan-cards-cont>:nth-child(2){
    width: 30%;
    height: 32rem;
    background-image: var(--planCard);
}

.planes-price{
    font-size: 3rem;
}
.plan-list-cont{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.plan-list{
display: flex;
gap: .5rem;

}
.plan-list>img{
width: 1rem;
height: 1rem;
margin: auto 0;
}
.plan-penifits{
    display: flex;
    align-items: center;
    gap: .5rem;
}
.plan-penifits>span{
    font-size: .8rem;
}
.plan-penifits>img{
    width: .8rem;
}
@media screen and (max-width: 900px) {
    .plans-head-cont{
        flex-direction: column;
        align-items: center;
        gap: 1.4rem;
    }
    .plan-cards-cont{
        display: flex;
        flex-direction: column;
        width: 90%;
    }
    .plan-one{
        width: 55%;
        font-size: 1.5rem;
        height: 35rem;
        }
    .plan-cards-cont>:nth-child(2){
        width: 55%;
        height: 35rem;
    }
    .plan-penifits>span{
        font-size: 1.2rem;
    }
}
    .plans-blur{
        width: 22rem;
        height: 30rem;
    }
    .blue-l{
        left: 0;
    }
    .blue-r{
        right: 0;
    }
@media screen and (max-width: 900px) {
    .plans-blur{
        width: 36rem;
        height: 45rem;
    }
    .blue-l{
        left: calc(50% - 18rem);
        top: 0;
    }
    .blue-r{
        display: none;
    }
}