main{
font-size: 16px;
}
.hero{
    display: flex;
    justify-content: space-between;
}

.l-side{
    padding: 1.5em 2em 2em 2em;
    width: 75%;
    display: flex;
    flex-direction: column;
    gap: 1.7rem;
}
.r-side{
    width: 25%;
    background-color: var(--orange);
    position: relative;
}
.the-best-fitness{
    background-color: #393D42;
    margin-top: 4.3rem;
    width: fit-content;
    padding: 1.3em 1em;
    border-radius: 4rem;
    position: relative;
    z-index: -2;
}
.slider{
    background-color:var(--orange);
    height:calc(100% - .8rem) ;
    width: 26%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 4rem;
    z-index: -1;
    margin: .4rem .5rem;
}
.the-best-fitness>span{
    color: #ffffff;
    font-size: 1rem;
}
.shap-ideal-body{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}
.large{
    font-size: 7rem;
}
.hero-par{
    color:#ffffff;
    font-size: 1rem;
    word-spacing: 1px;
    letter-spacing: 1px;
    margin: 0;
}
.score-cont{
    display: flex;
    gap: 2rem;
}
.scores{
    display: flex;
    flex-direction: column;
}
.score-num{
    color: #ffffff;
    font-size: 2rem;
}
.score-type{
    color: var(--gray);
    text-transform: uppercase;
}
.heart-rate{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: var(--darkGrey);
    width:fit-content;
    padding: 1rem;
    border-radius: 10px;
    position: absolute;
    right: 4rem;
    top: 7rem;
}
.heart-rate>img{
    width: 2rem;
}
.score-n1{
    font-size: 1rem;
    text-transform: none;
}
.score-n2{
    font-size: 1.5rem;
}
.hero-img{
    width: 23rem;
    position: absolute;
    right: 8rem;
    top: 10rem;
}
.hero-back-img{
    position: absolute;
    top: 4rem;
    right: 20rem;
    z-index: -1;
    width: 15rem;

}
.calories-burner{
    display: flex;
    gap: 2rem;
    width:fit-content;
    background-color:var(--caloryCard);
    padding: 1rem;
    border-radius: 10px;
    position: absolute;
    top: 33rem;
    right: 27rem;
}
.calories-burner>img{
    width: 3rem;
}
.calories-dedailes{
    display: flex;
    flex-direction: column;
    width: 4rem;
} 

@media screen and (max-width: 900px) {
    .hero{
        flex-direction: column;
    }
    .shap-ideal-body{
		text-align: center;
    }
    .r-side{
        width: 100vw;
        height: 75vh;
        background-color: transparent;
        align-items: center;
        justify-content: center;
    }
    .l-side{
        width: 100%;
        background-color: transparent;
        align-items: center;
        gap: 3rem;
        padding: 0; 
    }
    .hero-par{
        text-align: center;
        width: 80%;
    }
    .score-cont{
        width: 90%;
        font-size: .9rem;
    }
    .score-num{
        font-size: 1.5rem;
    }
    .the-best-fitness{
        font-size: .8rem;
        padding: 1.5rem 1rem;
        margin: 0;
        margin-top: 2rem;
    }
    .heart-rate{
        left: 2rem;
        animation: none;
        height: 8rem;
        justify-content: space-between;
        padding: 2rem;
        width: 7rem;
    }
    .heart-rate>img{
        width: 3rem;
    }
    .score-n1{
        font-size: 1.3rem;
        text-transform: none;
    }
    .score-n2{
        font-size: 1.6rem;
    }
    .hero-img{
        width: 20rem;
        left: 9rem;
    }
    .hero-back-img{
        position: absolute;
        top: 2rem;
        left: 2rem;
        width: 22rem;
        animation: none;
        animation: none;
    }
    .calories-burner{
        top: 40rem;
        left: 2rem;
        width:fit-content;
        animation-name: none;
        height: 4rem;
        padding: 1.5rem;
        align-items: center;
    }
    .calories-burner>img{
        width: 3rem;
    }
    .calories-dedailes{
        width: 100%;
    }
}
.hero-blure{
    left:0;
    width: 22rem;
    height: 30rem;
}