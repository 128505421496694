.testimonials-cont{
    display: flex;
    width: 100%;
}
.right-all{
    padding:0 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
}
.testimonials{
    font-size: 1rem;
    font-weight: 800;
    color: var(--orange);
    text-transform: uppercase;
}
.aboutus-title-cont{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}
.test-timonials{
    display: flex;
}
.timonial-left{
    width: 75%;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
}
.timonial-review{
    font-size: 1rem;
    word-spacing: 1px;
    font-weight: lighter;
    letter-spacing: 2px;
    line-height: 2.5rem;
}
.timonial-name-status>:nth-child(1){
    color: var(--orange);
    
}
.timonial-right{
    width: 45%;
    position: relative;
}
.box{
    width: 16.8rem;
    height: 18.8rem;
}
.bordered{
    background-color: transparent;
    border: 3px solid var(--orange);
    position: absolute;
    top:-1rem;
    right: 11rem;

}
.solid{
    background-image: var(--planCard);
    position: absolute;
    top: 1rem;
    right: 9rem;
}
.timoinal-image{
    height: 18.8rem;
    width: 16.8rem;
    object-fit:cover ;
    position: absolute;
    top: 0;
    right: 10rem;
}
.timonial-arrows{
    position: absolute;
    right: 37rem;
    top: 18rem;
    display: flex;
    align-self: center;
    gap: 1rem;
}
.timonial-left-arrow{
    width: 1.5em;
    cursor: pointer;
}
.timonial-right-arrow{
    width: 1.5em;
    transform: rotate(180deg);
    cursor: pointer;
}
@media screen and (max-width: 900px) {
    .testimonials-cont{
        flex-direction: column;
        font-size: 1.2rem;
    }
    .box{
        display: none;
    }
    .timonial-right{
        align-items: center;
        justify-content: center;
        width: 90%;
    }
    .timoinal-image{
        position:unset;
        width: 18rem;
        display: flex;
        margin:4rem auto;
    }
    .timonial-arrows{
        position: unset;
        align-items: center;
        justify-content: center;
        margin:4rem ;
    }
    .timonial-left{
        width: 90%;
        font-size: 1.2rem;
    }
    .testimonials{
        font-size: 1.4rem;
    }
    .timonial-review{
        font-size: 1.2rem;
        word-spacing: 8px;
    }
}
