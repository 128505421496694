.ex-head-cont{
    width: 75%;
    display: flex;
    justify-content: space-between;
    margin: auto;
    margin-bottom: 2rem;
}
.cards-container{
    display: flex;
    width: 95%;
    margin: auto;
    gap: 1rem;
}

.card-one{
    background-color: rgb(128,128,128);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 14rem;
    gap: 1rem;
    padding: 2em;
    color: #ffffff;
    font-size: .9rem;
    flex: 1;
    cursor:pointer;
}
.card-one:first-child{
    color: #ffffff;
}
.card-one:hover{
background-image: var(--planCard);
}
.ex-header{
font-size: 1rem;
font-weight: 800;
}
.ex-detailes{
    line-height: 1.4rem;
    font-weight: lighter;
}
.join-now{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 60%;
}
.join-now>span{
font-size: 1rem;
font-weight: lighter;
}
.join-now>img{
width: 1rem;
}
path{
    fill:#ffffff;
}
@media screen and (max-width: 900px) {
    .ex-head-cont{
    flex-direction: column;
    text-align: center;
    gap: 1.2rem;
    margin-top: 3rem;
    }
    .part-cont{
        align-items: center;
    }
    .cards-container{
        flex-direction: column;
        height: 80rem;
        width: 90%;
        gap: 2rem;

    }
    .card-one{
        font-size: 1.2rem;
    }
    .ex-header{
        font-size: 1.3rem;
        font-weight: 800;
        }
}
