.line{
    width: 10rem;
    height: 1px;
    border: 1px solid var(--orange);
    border-radius: 50%;
    margin-bottom: 1rem;
}
.join-cont{
    width: 95%;
    margin: auto;
    display: flex;
    gap: 10rem;
    align-items: flex-end;
}
.email-input{
    width: 21rem;
    background-color: rgb(128,128,128);
    height: 4em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:.2rem 1rem;
}
.email-input>input{
    height: 80%;
    width:60%;
    background-color: transparent;
    border: none;
    color: var(--lightgray);
}
.email-input>input:focus{
    outline: none;
}
::placeholder {
    color: var(--lightgray);
    opacity: 1;
}
@media screen and (max-width : 900px) {
    .join-cont{
        flex-direction: column;
        align-items: flex-start;
        gap: 3rem;
    }
    .email-input{
        width: 90%;
        max-width: 25rem;
        margin: auto;
        height: 5em;
        display: flex;
    }
    ::placeholder {
        font-size: .8rem;
    }
}