.pack-cont{
    display: flex;
    padding: 2rem;
    gap: 2rem;
}
.colection-cont{
    flex: 1;
    display: flex;
    height: 28rem;
    gap: 1rem;
}
.collect-pack1{
    height: 100%;
    width: 12rem;
    object-fit: cover;
}
.collect-pack2{
    display: flex;
    height: 100%;
    flex-direction: column;
    gap: 1rem;
}
.collect-pack2-1{
    height:auto;
    height:calc(60% - 0.5rem) ;
    object-fit: cover;

}
.collect-pack2-2{
    display: flex;
    gap: 1rem;
    height:calc(40% - 0.5rem) ;
    flex: 1;
}
.collect-pack2-2-1{
    width:calc(60% - .5rem) ;
    object-fit: cover;
}
.collect-pack2-2-2{
    width:calc(40% - .5rem) ;
    object-fit: cover;
}
.reasons-cont{
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.some-reasons{
    font-size: 1rem;
    font-weight: 800;
    color: var(--orange);
    text-transform: uppercase;
}
.reason-list-cont{
    display: flex;
    flex-direction: column;
    gap: 1.3rem;
}
.reason-list{
    display: flex;
    gap: .5rem;
    align-items: center;
}
.reason-list>img{
    width: 2rem;
}
.reason-list>span{
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 800;
    color: #ffffff;
}
.parteners{
    font-size: 1.3rem;
font-weight: lighter;
color: var(--gray);
}
.logos-cont{
    display: flex;
    gap: 1.5rem;
}
.logos-cont>img{
    width: 2.5rem;
}
@media screen and (max-width: 900px) {
    .pack-cont{
        flex-direction: column;
    }
    .colection-cont{
        gap: 4vw;
    }
    .collect-pack1{
        height: 80vw;
        width: 25vw;
        max-height: 40rem;
    }
    .collect-pack2{
        height: 80vw;
        width: 55vw;
        max-height: 40rem;
    }
    .collect-pack2-2{
        gap: 4vw;
    }
    .reasons-cont{
        gap: 2rem;
    }
    .some-reasons{
        font-size: 1.3rem;
    }
    .reason-list>img{
        width: 2.5rem;
    }
    .reason-list>span{
        font-size: 1.3rem;
    }
    .parteners{
        font-size: 1.8rem;
    }
    .logos-cont>img{
        width: 3rem;
    }
}
