footer{
    height: 48vh;
    border-top: 1px solid #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4rem;
    position: relative;
}
.social-cont{
    display: flex;
    gap: 4rem;
}
.foot-icon{
    font-size: 2rem;
}
.footer-blur {
    width: 15rem;
    height: 20rem;
}
.f-blue-l{
    left: 20rem;
}
.f-blue-r{
    right: 20rem;
	background: rgba(253, 43, 43, 0.69);
}
@media screen and (max-width: 900px) {
    .footer-blur {
        width: 23rem;
        height: 30rem;
    }
}